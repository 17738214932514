<template>
  <div class="container">
    <div class="join-bg">
      <div class="bg-text bg-text-1">提交您的简历</div>
      <div class="bg-text bg-text-1">让我们更好的了解您</div>
      <div class="join-background"></div>
    </div>
    <div class="join-pc-background"></div>
    <div class="join-form-container">
      <div class="title">加入我们</div>
      <div class="subtitle">提交您的简历，让我们更好的了解您。</div>
      <div class="join-form">
        <div class="form-item">
          <span class="form-item-label">手机号:</span>
          <input class="join-phone" type="number" placeholder="请输入手机号" v-model="form.phone" />
          <span v-if="phoneTip" class="tip">{{ phoneTip }}</span>
        </div>
        <div class="form-item">
          <span class="form-item-label">验证码:</span>
          <input class="join-yzm" type="number" placeholder="请输入验证码" v-model="form.yzm" /><button @click="onGetYzm" :disabled="yzmLoading" class="btn btn-primary join-btn-yzm"><i v-if="yzmLoading" class="iconfont icon-loading"/>获取验证码<span v-if="timer">{{ countDown }}s</span></button>
          <span v-if="yzmTip" class="tip">{{ yzmTip }}</span>
        </div>
        <div v-if="msg" class="message">
          {{ msg }}
        </div>
      </div>
      <button class="join-btn-submit" :disabled="loading" @click="onSubmit"><i v-if="loading" class="iconfont icon-loading"/>登陆</button>
    </div>
    <div class="divide"></div>
  </div>
</template>
<script>
import { getYzm, login } from '@/http/api.js'
export default {
  data(){
    return {
      form: {
        phone: '',
        yzm: ''
      },
      phoneTip: '',
      yzmTip: '',
      loading: false,
      yzmLoading: false,
      msg: '',
      countDown: 90,
      timer: null
    }
  },
  methods: {
    checkPhone(){
      const phone = this.form.phone.trim()
      const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]7|[0-8]|8\d|9[0-35-9])\d{8}$/
      if(phone){
        if(!reg.test(phone)){
          this.phoneTip = '手机号格式错误'
          return false
        }else{
          this.phoneTip = ''
          return true
        }
      }else{
        this.phoneTip = '手机号不能为空'
        return false
      }
    },
    checkYzm(){
      const yzm = this.form.yzm.trim()
      if(!yzm){
        this.yzmTip = '验证码不能为空'
        return false
      }else{
        this.yzmTip = ''
        return true
      }
    },
    onGetYzm(){
      if(this.checkPhone()){
        this.yzmLoading = true
        getYzm(this.form.phone).then(res=> {
          this.yzmLoading = false
          if(res.status === 200){
            // this.msg = res.message
            this.$toast.open(res.message)
            this.startCountDown()
          }
        }).catch(()=>{
          this.yzmLoading = false
        })
      }
    },
    startCountDown(){
      this.timer = setInterval(() => {
        this.countDown --
        if(this.countDown <= 0){
          clearInterval(this.timer)
        }
      }, 1000);
    },
    clearTimer(){
      if(this.timer){
        clearInterval(this.timer)
      }
      this.timer = null
      this.countDown = 90
    },
    onSubmit(){
      if(this.checkPhone() && this.checkYzm()){
        this.loading = true
        login({ phone: this.form.phone, code: this.form.yzm }).then(res => {
          this.loading = false
          if(res.status === 200){
            this.msg = ''
            this.clearTimer()
            sessionStorage.setItem('token', res['data'])
            this.$router.push('/register')
          }
        }).catch(()=>{
          this.loading = false
        })
      }
    }
  },
  beforeDestroy(){
    this.clearTimer()
  }
}
</script>
